import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchPost, deletePost } from '../../services/api';
import './Board.css';

function PostDetail() {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const currentUser = useSelector((state) => state.auth.user);
  
  useEffect(() => {
    const loadPost = async () => {
      try {
        const data = await fetchPost(id);
        setPost(data);
      } catch (err) {
        setError('게시글을 불러오는데 실패했습니다.');
        console.error('Error loading post:', err);
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [id]);

  const handleDelete = async () => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    if (post.user_id !== currentUser.id) {
      setError('자신의 게시글만 삭제할 수 있습니다.');
      return;
    }

    if (window.confirm('정말로 삭제하시겠습니까?')) {
      try {
        await deletePost(id);
        navigate('/posts');
      } catch (err) {
        setError('게시글 삭제에 실패했습니다.');
        console.error('Error deleting post:', err);
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!post) return <div>게시글을 찾을 수 없습니다.</div>;

  const isOwner = isLoggedIn && currentUser && post.user_id === currentUser.id;

  return (
    <div className="post-detail">
      <h2>{post.title}</h2>
      <div className="post-meta">
        <span className="post-author">작성자: {post.author_name}</span>
        <span className="post-date">작성일: {new Date(post.created_at).toLocaleDateString()}</span>
        {post.updated_at && post.updated_at !== post.created_at && (
          <span className="post-date">수정일: {new Date(post.updated_at).toLocaleDateString()}</span>
        )}
      </div>
      <div className="post-content">{post.content}</div>
      <div className="post-actions">
        {isOwner && (
          <>
            <button onClick={() => navigate(`/posts/${id}/edit`)}>수정</button>
            <button onClick={handleDelete}>삭제</button>
          </>
        )}
        <button onClick={() => navigate('/posts')}>목록으로</button>
      </div>
    </div>
  );
}

export default PostDetail; 