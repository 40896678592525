import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchPosts } from '../../services/api';
import './Board.css';

function PostList() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const data = await fetchPosts();
        setPosts(data);
      } catch (err) {
        setError('게시글을 불러오는데 실패했습니다.');
        console.error('Error loading posts:', err);
      } finally {
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="post-list">
      <h2>게시판</h2>
      {isLoggedIn ? (
        <Link to="/posts/new" className="new-post-button">
          새 글 작성
        </Link>
      ) : (
        <Link to="/login" className="new-post-button">
          로그인하고 글쓰기
        </Link>
      )}
      <div className="posts">
        {posts.map(post => (
          <div key={post.id} className="post-item">
            <Link to={`/posts/${post.id}`}>
              <h3>{post.title}</h3>
              <div className="post-meta">
                <span className="post-author">작성자: {post.author_name}</span>
                <span className="post-date">{new Date(post.created_at).toLocaleDateString()}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PostList; 