import React from 'react';

export default function Placeholder({ userId }) {
    const placeholderStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        border: '2px dashed #007bff',
        borderRadius: '8px',
        backgroundColor: '#f0f8ff',
        color: '#007bff',
        fontSize: '1.5em',
        fontWeight: 'bold',
        textAlign: 'center',
        margin: '20px',
    };
    return (
        <div className="placeholder" style={placeholderStyle}>
            <span>{userId}</span>
        </div>
    );
}
