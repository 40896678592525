import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../features/auth/authSlice';
import './Navigation.css';

function Navigation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <nav className="nav-menu">
      <div className="nav-left">
        {/* <Link to="/">홈</Link> */}
        <Link to="/posts">게시판</Link>
        <Link to="/live">라이브</Link>
      </div>
      <div className="nav-right">
        {isLoggedIn ? (
          <>
            <span className="user-name">{user.name}님</span>
            <button onClick={handleLogout} className="logout-button">
              로그아웃
            </button>
          </>
        ) : (
          <>
            <Link to="/login" className="login-button">로그인</Link>
            <Link to="/signup" className="signup-button">회원가입</Link>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navigation; 