import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    nick: "",

    signupInfo: {
        memberType: "normal",
        email: "",
        nick: "",
    }
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
});

export default userSlice.reducer;
