import { Navigate } from 'react-router-dom';
import PostList from '../components/Board/PostList';
import PostDetail from '../components/Board/PostDetail';
import PostForm from '../components/Board/PostForm';
import NotFound from '../components/NotFound';
import Home from '../components/Home';
import Login from '../components/Auth/Login';
import Signup from '../components/Auth/Signup';
import Live from '../components/Live';
const routes = (isLoggedIn) => [
  {
    path: '/',
    element: isLoggedIn ? <Home /> : <Navigate to="/login" />,
  },
  {
    path: '/posts',
    children: [
      { path: '', element: <PostList /> },
      { path: 'new', element: isLoggedIn ? <PostForm /> : <Login /> },
      { path: ':id/edit', element: isLoggedIn ? <PostForm /> : <Login /> },
      { path: ':id', element: <PostDetail /> },
    ],
  },
  {
    path: '/login',
    element: isLoggedIn ? <Navigate to="/" /> : <Login />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/live',
    element: <Live />,
  },
];

export default routes; 