import React from 'react';
import { useSelector } from "react-redux";
import { useRoutes } from 'react-router-dom';
import Navigation from './components/Navigation';
import routes from './routes';
import './App.css';

function App() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const routing = useRoutes(routes(isLoggedIn));
  
  return (
    <div className="App">
      <Navigation />
      {routing}
    </div>
  );
}

export default App;
