import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home">
      <h1>Welcome to Web App</h1>
      <p>간단한 웹 애플리케이션입니다.</p>
    </div>
  );
}

export default Home; 