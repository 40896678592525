
import React from 'react';
import JoinStage from '../StageJoin';
import StageParticipants from '../StageParticipants';
import StageProvider from '../../contexts/StageContext';

function Live() {
  return (
    <div>
      <StageProvider>
        <div>
          <StageParticipants />
          <JoinStage />
        </div>
      </StageProvider>
    </div>
  );
}

export default Live;