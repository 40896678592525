import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchPost, createPost, updatePost } from '../../services/api';
import './Board.css';
import { useSelector } from 'react-redux';

function PostForm() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = !!id;
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    const loadPost = async () => {
      try {
        setLoading(true);
        const data = await fetchPost(id);
        
        // Check if the current user is the post owner
        if (data.user_id !== currentUser.id) {
          setError('자신의 게시글만 수정할 수 있습니다.');
          navigate(`/posts/${id}`);
          return;
        }

        setTitle(data.title);
        setContent(data.content);
      } catch (err) {
        setError('게시글을 불러오는데 실패했습니다.');
        console.error('Error loading post:', err);
      } finally {
        setLoading(false);
      }
    };

    if (isEdit) {
      loadPost();
    }
  }, [id, isEdit, currentUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      if (isEdit) {
        await updatePost(id, { title, content, user_id: currentUser.id });
      } else {
        await createPost({ title, content, user_id: currentUser.id });
      }
      navigate('/posts');
    } catch (err) {
      setError(isEdit ? '게시글 수정에 실패했습니다.' : '게시글 작성에 실패했습니다.');
      console.error('Error saving post:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="post-form">
      <h2>{isEdit ? '게시글 수정' : '새 게시글 작성'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">제목</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">내용</label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </div>
        <div className="form-actions">
          <button type="submit" disabled={loading}>
            {isEdit ? '수정' : '작성'}
          </button>
          <button type="button" onClick={() => navigate('/posts')} disabled={loading}>
            취소
          </button>
        </div>
      </form>
    </div>
  );
}

export default PostForm; 