import React, { useRef, useEffect } from 'react';
import './Video.css';
export default function Video({ stageStream }) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stageStream) {
            videoRef.current.srcObject = new MediaStream([stageStream.mediaStreamTrack]);
        }
    }, [videoRef, stageStream]);

    return (
        <div className="video-container">
            <video ref={videoRef} autoPlay playsInline />
        </div>
    );
}
