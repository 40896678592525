import React, { useEffect, useState, useContext } from 'react';
import LocalVideo from '../LocalVideo';
import Button from '../Button';
import Select from '../Select';
import { getDevices } from '../../util/mediaDevices';
import { LocalMediaContext } from '../../contexts/LocalMediaContext';
import { StageContext } from '../../contexts/StageContext';
import { useLocalMedia } from '../../hooks/useLocalMedia';

export default function JoinStage() {
    // This is for development purposes. It checks to see if we have a valid token saved in the session storage
    const cachedStageToken = sessionStorage.getItem('stage-token');
    const [stageToken, setStageToken] = useState(cachedStageToken || '');
    const { joinStage, stageJoined, leaveStage } = useContext(StageContext);

    function joinOrLeaveStage() {
        if (stageJoined) {
            leaveStage();
        } else {
            joinStage(stageToken);
        }
    }

    return (
        <div className="row">
            <div className="column">
                <div className="row" style={{ marginTop: '2rem' }}>
                    <div className="column">
                        <label htmlFor="token">Token</label>
                        <input
                            type="text"
                            value={stageToken}
                            onChange={(e) => setStageToken(e.target.value)}
                            id="token"
                            name="token"
                        />
                    </div>
                    <div className="column" style={{ display: 'flex', marginTop: '1.5rem' }}>
                        <Button onClick={joinOrLeaveStage}>{stageJoined ? 'Leave' : 'Join'}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
