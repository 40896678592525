import React, { useContext } from 'react';
import { StageContext } from '../contexts/StageContext';
import StageStream from './StageStream';
import Placeholder from './Placeholder';
export default function StageParticipants() {
    const { participants } = useContext(StageContext);

    return (
        <div>
            {(() => {
                if (participants.size === 0) {
                    return <Placeholder userId="지금은 오프라인입니다" />;
                } else {
                    return [...participants.keys()].map((key) => {
                        return <StageStream key={key} {...participants.get(key)} />;
                    });
                }
            })()}
        </div>
    );
}
